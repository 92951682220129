import React from 'react'

export default function ModalWrapper({children}) {
  return (
    <>
      <div id="modal" />
      
      {children}
    </>
  )
}
