// Core
import React, {memo, useCallback, useState} from 'react'

// Components
import ModalWrapper from '../../../Modal/ModalWrapper'
import Modal from '../../../Modal/Modal'
import FirstStep from '../Modals/FirstStep'
import LastStep from '../Modals/LastStep'

const Guides = ({setIsShowModal}) => {
  const [step, setStep] = useState(1)

  switch (step) {
    case 1:
      return <FirstStep nextStep={() => setStep(2)} onCloseHandler={() => setIsShowModal(false)} />
      break
    case 2:
      return <LastStep onCloseHandler={() => setIsShowModal(false)} />

    default:
      return null
  }
}

export default function useGuideModal() {
  const [isShowModal, setIsShowModal] = useState(false)

  const GuidesWrapper = useCallback(() => {
    return (
      <ModalWrapper>
        {isShowModal ? (
          <Modal>
            <Guides setIsShowModal={setIsShowModal} />
          </Modal>
        ) : null}
      </ModalWrapper>
    )
  }, [isShowModal, setIsShowModal])

  return [GuidesWrapper, {showModal: () => setIsShowModal(true)}]
}
