import React from 'react'

import * as styles from '../../assets/css/pages/onesearch.module.css'

export default function HeroDefault({content, children}) {
  return (
    <section className="atf_Section ff">
      <div className="left">
        <img
          src="https://s.yimg.com/cv/apiv2/onesearch/images/search-term-encryption-illustration.png"
          srcSet="https://s.yimg.com/cv/apiv2/onesearch/images/search-term-encryption-illustration.png 1x, https://s.yimg.com/cv/apiv2/onesearch/images/search-term-encryption-illustration_2x.png 2x"
          alt="Search term encryption"
          className="img-responsive max-344"
        />
      </div>
      <div className="right">
        <div className="logo_Wrapper">
          <img
            src="https://s.yimg.com/cv/apiv2/onesearch/images/onesearch_350x75_light.png"
            srcSet="https://s.yimg.com/cv/apiv2/onesearch/images/onesearch_350x75_light.png 1x, https://s.yimg.com/cv/apiv2/onesearch/images/onesearch_350x75_light_2x.png 2x"
            alt="Logo"
            className="img-responsive"
          />
        </div>
        <h1 className="atf_Title">Your privacy is our first priority</h1>
        <h2 className="atf_Sub_Title">{content.sub_title}</h2>
        <div className="atf_optionSec_wrapper">
          <div className="atf_optionSec">
            <div className="option_blck">
              <span className="op_tick">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>Green tick</title>
                  <g id="Green-tick" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                      d="M12,21 C7.038,21 3,16.963 3,12 C3,7.037 7.038,3 12,3 C16.962,3 21,7.037 21,12 C21,16.963 16.962,21 12,21 M12,1 C5.925,1 1,5.925 1,12 C1,18.075 5.925,23 12,23 C18.074,23 23,18.075 23,12 C23,5.925 18.074,1 12,1 M17.6817,8.3046 C17.2727,7.8966 16.6087,7.8966 16.1997,8.3046 L10.0147,14.4766 L7.7917,12.2606 C7.3827,11.8516 6.7197,11.8516 6.3107,12.2606 C5.9017,12.6686 5.9017,13.3296 6.3107,13.7376 L9.2737,16.6936 C9.6827,17.1016 10.3467,17.1016 10.7557,16.6936 L17.6817,9.7826 C18.0907,9.3746 18.0907,8.7126 17.6817,8.3046 L17.6817,8.3046 Z"
                      id="Fill-35"
                      fill="#1BB87D"></path>
                  </g>
                </svg>
              </span>
              <span className="op_text">No cookies</span>
            </div>
            <div className="option_blck">
              <span className="op_tick">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>Green tick</title>
                  <g id="Green-tick" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                      d="M12,21 C7.038,21 3,16.963 3,12 C3,7.037 7.038,3 12,3 C16.962,3 21,7.037 21,12 C21,16.963 16.962,21 12,21 M12,1 C5.925,1 1,5.925 1,12 C1,18.075 5.925,23 12,23 C18.074,23 23,18.075 23,12 C23,5.925 18.074,1 12,1 M17.6817,8.3046 C17.2727,7.8966 16.6087,7.8966 16.1997,8.3046 L10.0147,14.4766 L7.7917,12.2606 C7.3827,11.8516 6.7197,11.8516 6.3107,12.2606 C5.9017,12.6686 5.9017,13.3296 6.3107,13.7376 L9.2737,16.6936 C9.6827,17.1016 10.3467,17.1016 10.7557,16.6936 L17.6817,9.7826 C18.0907,9.3746 18.0907,8.7126 17.6817,8.3046 L17.6817,8.3046 Z"
                      id="Fill-35"
                      fill="#1BB87D"></path>
                  </g>
                </svg>
              </span>
              <span className="op_text">No user tracking</span>
            </div>
            <div className="option_blck">
              <span className="op_tick">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink">
                  <title>Green tick</title>
                  <g id="Green-tick" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                      d="M12,21 C7.038,21 3,16.963 3,12 C3,7.037 7.038,3 12,3 C16.962,3 21,7.037 21,12 C21,16.963 16.962,21 12,21 M12,1 C5.925,1 1,5.925 1,12 C1,18.075 5.925,23 12,23 C18.074,23 23,18.075 23,12 C23,5.925 18.074,1 12,1 M17.6817,8.3046 C17.2727,7.8966 16.6087,7.8966 16.1997,8.3046 L10.0147,14.4766 L7.7917,12.2606 C7.3827,11.8516 6.7197,11.8516 6.3107,12.2606 C5.9017,12.6686 5.9017,13.3296 6.3107,13.7376 L9.2737,16.6936 C9.6827,17.1016 10.3467,17.1016 10.7557,16.6936 L17.6817,9.7826 C18.0907,9.3746 18.0907,8.7126 17.6817,8.3046 L17.6817,8.3046 Z"
                      id="Fill-35"
                      fill="#1BB87D"></path>
                  </g>
                </svg>
              </span>
              <span className="op_text">No search history</span>
            </div>
          </div>
        </div>

        {/* BEGIN CHILDREN */}
        <div className={`${styles.cta_up}`}>{children}</div>
        {/* END CHILDREN */}
      </div>
    </section>
  )
}
